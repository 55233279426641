import { AtpAgent } from '@atproto/api';
import { LOCAL_STORAGE_SESSION_KEY } from '~/config/robinfeed';

const getSessionFromLocalStorage = () => {
  return typeof localStorage !== 'undefined' ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_SESSION_KEY) || 'null') : null;
};

const localSession = getSessionFromLocalStorage();

export const atpClient = {
  agent: new AtpAgent({
    service: typeof window !== 'undefined' && localSession && localSession.service ? localSession.service : 'https://bsky.social',
  }),

  initializeAgent(service: string) {
    this.agent = new AtpAgent({
      service,
    });
    console.log('agent:', this.agent);
  },
};

if (typeof window !== 'undefined' && import.meta.env.DEV) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).atpClient = atpClient;
}
