export const ROBIN_FEED_IDENTIFIER_PREFIX = 'robinfeed-'; // Must be 10 characters max
export const ROBIN_FEED_WATERMARK = '\n\n⚡ RobinFeed';

// Auth
export const LOCAL_STORAGE_SESSION_KEY = 'RobinFeed_Session';
export const LOCAL_STORAGE_SETTING_KEY = 'RobinFeed_Settings';

// Feed Avatar
export const MAX_AVATAR_SIZE = 64; // 64KB
export const FEED_AVATAR_ICON = 'bluesky';
export const PROFILE_AVATAR_ICON = 'user';

// Max Lengths
export const FEED_ID_MAX_LENGTH = 15;
export const DISPLAY_NAME_MAX_LENGTH = 24;
export const DESCRIPTION_MAX_LENGTH = 300 - ROBIN_FEED_WATERMARK.length;
export const TAG_MAX_LENGTH = 100;
export const DISPLAY_NAME_TRUNCATE_LENGTH = 20;
export const DESCRIPTION_TRUNCATE_LENGTH = 35;
