import { getAgentSession } from '~/services/api/auth';

const NETWORK_ERRORS = ['Abort', 'Network request failed', 'Failed to fetch', 'Load failed'];

export function isNetworkError(e: unknown) {
  const str = String(e);
  for (const err of NETWORK_ERRORS) {
    if (str.includes(err)) {
      return true;
    }
  }
  return false;
}

export function isInvalidCredentialsError(error: unknown): boolean {
  return typeof error === 'object' && error !== null && 'message' in error && (error as { message: string }).message === 'Invalid identifier or password';
}

export const getStatusCode = (error: unknown): number => {
  if (error && typeof error === 'object') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const status = (error as any).status || (error as any).statusCode;

    if (status) {
      return status;
    }
  }
  return 0;
};

export const getErrorMessage = (error: unknown): string => {
  if (error && typeof error === 'object') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errorMessage = (error as any).statusText || (error as any)._data?.error || (error as any).message;

    if (errorMessage) {
      return errorMessage;
    }
  }
  return 'An unexpected error occurred';
};

let sessionRevalidationPromise: Promise<unknown> | null = null;
export const catchUnauthorizedErrors = <T>(callback: () => Promise<T>) => {
  return callback().catch(async (error) => {
    if (error && error.status === 401) {
      if (!sessionRevalidationPromise)
        sessionRevalidationPromise = getAgentSession({
          forceRefresh: true,
        });
      return sessionRevalidationPromise.then(async () => {
        return await callback();
      });
    }
    throw error;
  });
};
